import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import { WIDTH } from '../../constants/screenResolution';

export const NewHeroCarouselWrapper = styled.div`
  display: flex;
  padding: 16px 0;
  background: ${(props) => props.theme.palette.background.bg2};
  max-height: 280px;
  @media (max-width: ${WIDTH.desktopMin}) {
    max-height: 280px;
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    height: 100%;
    max-height: 280px;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    max-height: 100%;
    padding: 0;
  }
`;
``;

export const NewHeroCarouselContainer = styled.div`
  display: grid;
  position: relative;
  max-width: 1688px;
  margin: auto;
  width: 100%;
  // padding: 0 16px;
  grid-template-columns: 100%;
  overflow: hidden;
  background: ${(props) => props.theme.palette.background.bg2};
  max-height: 280px;
  height: 100%;
  // > div {
  border-radius: ${(props) => props.theme.palette.borderRadius.b4};
  // }
  @media (max-width: ${WIDTH.tabletMax}) {
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-gap: 0;
    padding: 0 0;
  }
  @media (max-width: ${WIDTH.laptopMid}) {
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-gap: 0;
    padding: 0 0;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    max-height: 178px;
  }
`;

export const NextArrowWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  z-index: 1;
  right: 16px;
  left: auto;
  width: 172px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0 16px 16px 0;

  height: 280px;
  @media (max-width: 1728px) {
    width: 94px;
    height: 280px;
  }
  @media (max-width: 1439px) {
    width: 64px;
    height: 280px;
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    width: 64px;
    height: 280px;
    background: none;
    > span {
      display: none;
    }
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    height: 178px;
    width: 12px;
    > span {
      display: none;
    }
  }
`;

export const PreviousArrowWrapper = styled.div`
  display: flex;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 16px;
  right: auto;
  width: 172px;
  align-items: center;
  justify-content: center;
  transform: rotate(180deg);
  cursor: pointer;
  border-radius: 0 16px 16px 0;

  height: 280px;
  @media (max-width: 1728px) {
    width: 94px;
    height: 280px;
  }
  @media (max-width: 1439px) {
    width: 64px;
    height: 280px;
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    width: 64px;
    height: 280px;
    background: none;

    > span {
      display: none;
    }
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    width: 12px;
    height: 178px;
    > span {
      display: none;
    }
  }
`;

export const NextArrowWrapperBg = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  z-index: 1;
  right: 16px;
  left: auto;
  width: 172px;
  cursor: pointer;
  border-radius: 0 16px 16px 0;
  background: linear-gradient(
    to right,
    ${COLORS.transparent},
    rgba(0, 0, 0, 0.6)
  );
  height: 280px;
  @media (max-width: 1728px) {
    width: 94px;
    height: 280px;
  }
  @media (max-width: 1439px) {
    width: 64px;
    height: 280px;
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    width: 64px;
    height: 280px;
    background: none;
    > span {
      display: none;
    }
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    height: 178px;
    width: 12px;
    > span {
      display: none;
    }
  }
`;
export const PreviousArrowWrapperBg = styled.div`
  display: flex;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 16px;
  right: auto;
  width: 172px;
  align-items: center;
  justify-content: center;
  transform: rotate(180deg);
  cursor: pointer;
  border-radius: 0 16px 16px 0;
  background: linear-gradient(
    to right,
    ${COLORS.transparent},
    rgba(0, 0, 0, 0.6)
  );
  height: 280px;
  @media (max-width: 1728px) {
    width: 94px;
    height: 280px;
  }
  @media (max-width: 1439px) {
    width: 64px;
    height: 280px;
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    width: 64px;
    height: 280px;
    background: none;

    > span {
      display: none;
    }
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    width: 12px;
    height: 178px;
    > span {
      display: none;
    }
  }
`;

export const ArrowBgWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  opacity: 0.4;
  width: 96px;
`;

export const AdditionalContent = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.palette.background.bg1};
  max-height: 371px;
  width: 428px;
  gap: 16px;
  @media (max-width: ${WIDTH.laptopMid}) {
    width: 100%;
    max-height: 100%;
    height: 100%;
    margin-top: 16px;
    padding: 32px 32px 16px;
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    width: 100%;
    max-height: 100%;
    height: 100%;
    padding: 44px 28px 16px;
    margin-top: 0;
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    width: 100%;
    max-height: 100%;
    height: 100%;
    padding: 44px 16px 16px;
    margin-top: 0;
  }
`;

export const CarouselItemContainer = styled.div`
  height: 280px;
  position: relative;
  border-radius: ${(props) => props.theme.palette.borderRadius.b4};
  background: ${(props) => props.theme.palette.background.bg2};
  width: 100%;
  padding: 0 8px;
  // > div {
  //   margin: 0 8px !important;
  //   #carousel-banner-image {
  //     border-radius: ${(props) => props.theme.palette.borderRadius.b4};
  //     cursor: pointer;
  //   }
  // }
  > a > span {
    margin: 0 16px !important;
    #carousel-banner-image {
      border-radius: ${(props) => props.theme.palette.borderRadius.b4};
      cursor: pointer;
    }
  }
  @media (max-width: ${WIDTH.desktopMin}) {
    max-height: 280px;
    width: 100%;
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    width: 100%;
    max-height: 280px;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    width: 100%;
    max-height: 178px;
  }
`;

export const CarouselItemWrapper = styled.img`
  border-radius: ${(props) => props.theme.palette.borderRadius.b4};
  height: 280px;
  width: 100%;
  cursor: pointer;
  object-fit: cover;
  object-position: center;
  @media (max-width: ${WIDTH.desktopMin}) {
    width: 100%;
    height: 280px;
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    width: 100%;
    height: 280px;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    width: 100%;
    height: 178px;
  }
`;

export const ContentTitle = styled.div`
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-family: Onest-Bold;
  color: ${(props) => props.theme.palette.text.t1};
  display: none;

  @media (max-width: ${WIDTH.tabletMax}) {
    display: flex;
  }
`;
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  @media (max-width: ${WIDTH.laptopMid}) {
    flex: 1 1;
    flex-direction: row;
    height: fit-content;
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    flex: 1 1;
    flex-direction: row;
    height: fit-content;
  }
`;
export const ContentItem = styled.div`
  display: flex;
  width: 428px;
  cursor: pointer;
  justify-content: space-between;
  align-items: flex-end;
  padding: 32px 32px 0;
  height: 100%;
  > img {
    align-self: flex-end;
  }
  background: ${(props) => props.theme.palette.background.bg2};
  @media (max-width: ${WIDTH.laptopMid}) {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    gap: 16px;
    padding: 16px 48px 0;
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    gap: 16px;
    padding: 16px 32px 0;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    gap: 16px;
    padding: 16px 16px 0;
  }
`;
export const ContentItemTitle = styled.div`
  font-size: 1.5rem;
  line-height: 1.75rem;
  align-self: center;
  margin-bottom: 32px;
  font-family: Onest-Bold;
  color: ${(props) => props.theme.palette.text.t1};
  @media (max-width: ${WIDTH.tabletMax}) {
    font-size: 1.5rem;
    line-height: 1.75rem;
    margin-top: 0;
    align-self: center;
    margin-bottom: 32px;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-top: 0;
    align-self: flex-start;
    margin-bottom: 0;
  }
`;

export const ImageDropShadow = styled.div`
  filter: drop-shadow(0px 6.07692px 30.3846px #000000);
`;

export const ItemContent = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: 0 56px;
  height: 100%;
  @media (max-width: ${WIDTH.tabletMax}) {
    padding: 0 32px;
    width: 100%;
    height: 290px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 178px;
  }
`;
export const ItemContentLeft = styled.div`
  display: flex;
  margin: auto 0;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  max-width: 600px;
  gap: 16px;
  @media (max-width: ${WIDTH.mobileMax}) {
    justify-content: center;
  }
`;
export const ItemTitle = styled.div`
  font-size: 2.75rem;
  line-height: 2.875rem;
  color: ${(props) => props.theme.palette.text.t1};
  font-family: Onest-Bold;
  @media (max-width: ${WIDTH.desktopMin}) {
    font-size: 2.5rem;
    line-height: 2.75rem;
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    display: none;
  }
`;
export const ItemCaption = styled.div`
  font-size: 1rem;
  line-height: 1.25rem;
  color: ${(props) => props.theme.palette.text.t3};
  font-family: Onest-Medium;
  max-width: 400px;
  @media (max-width: ${WIDTH.tabletMax}) {
    display: none;
  }
`;
export const ItemButton = styled.div`
  margin-top: 16px;
  width: 204px;
  height: 58px;
  display: flex;
  align-item: center;
  transition: 0.3s all ease;
  @media (max-width: ${WIDTH.tabletMax}) {
    display: none;
  }

  > button {
    p {
      font-size: 1.25rem;
      line-height: 1.5rem;
      font-family: Onest-Bold;
    }
    border-radius: ${(props) => props.theme.palette.borderRadius.b2};
    background: rgb(255, 255, 255, 0.05);
    color: ${(props) => props.theme.palette.text.t1};
    #view-game-arrow {
      opacity: 0;
      transition: 0.3s all ease;
    }
    #view-game-text {
      margin-left: 30px;
      transition: 0.3s all ease;
    }
    &:hover {
      background: rgb(255, 255, 255, 0.05);
    }
  }
  &:hover {
    #view-game-arrow {
      opacity: 1;
    }
    #view-game-text {
      margin-left: 0px;
    }
  }
`;
interface ItemContentRightInterface {
  src: string;
}

export const ItemContentImage = styled.img`
  max-width: 150px;
  height: 100%;
  @media (max-width: ${WIDTH.mobileMax}) {
    max-width: 88px;
    height: 100%;
  }
`;

export const ItemContentRight = styled.div<ItemContentRightInterface>`
  display: flex;
  justify-content: flex-end;
  background: url(${(props) => props.src});
  background-size: contain;
  background-repeat: no-repeat;
  align-item: center;
  background-position: bottom;
  max-width: 300px;
  height: 100%;
  margin: -40px 0 0;
  width: 100%;
  @media (max-width: ${WIDTH.mobileMax}) {
    width: 100%;
    margin: auto;
    margin-top: 125px;
    width: 100%;
    height: 56px;
  }
`;
