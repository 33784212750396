import styled from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';

export const UnderPriceRangeSectionContainer = styled.div`
  // background-color: ${(props) => props.theme.palette.background.bg2};
`;

export const UnderPriceRangeSectionWrapper = styled.div`
  max-width: 1232px;
  margin: 0 auto 32px;
  margin-bottom: 0;
  padding: 0 16px;
  @media (max-width: ${WIDTH.tabletMax}) {
    margin: 0 0 24px;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    margin: 0px;
    margin-bottom: 0px;
  }
`;

export const MainSection = styled.section`
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(179px, 1fr));
  position: relative;
  place-items: center;
  // padding: 16px;
  width: 100%;
  grid-gap: 16px;
  // box-shadow: ${(props) => props.theme.shadows.s2};
  // background: ${(props) => props.theme.palette.background.bg1};
  // border-radius: ${(props) => props.theme.palette.borderRadius.b4};
  @media (max-width: ${WIDTH.mobileMax}) {
    // grid-gap: 12px;
    // grid-template-columns: repeat(auto-fill, minmax(107px, 1fr));
    grid-template-columns: 1fr 1fr;
    // background: ${(props) => props.theme.palette.background.bg2};
    box-shadow: none;
    place-items: normal;
    grid-gap: 12px;
    padding: 0;
  }
`;

interface PriceTilePropsI {
  bg: string;
}

export const PriceTile = styled.div<PriceTilePropsI>`
  // min-height: 118px;
  // min-width: 179px;
  width: 189px;
  height: 104px;
  padding: 16px;
  cursor: pointer;
  border-radius: 16px;
  background: ${(props) => props.theme.palette.button.grey.main};
  // box-shadow: ${(props) => props.theme.shadows.s2};
  border: 1px solid ${(props) => props.theme.palette.divider.d1};
  color: ${(props) => props.theme.palette.text.t1};
  display: grid;
  place-items: center;
  place-content: center;
  font-family: Onest-Bold;
  font-size: 32px;
  line-height: 36px;
  // color: ${(props) => props.theme.palette.white.main};
  text-align: center;
  // background: ${(props) => props.bg};
  background-size: 150% 100%;
  background-position: center;
  transition: 0.3s;
  & span {
    font-size: 16px;
    font-family: Onest-Medium;
    color: ${(props) => props.theme.palette.text.t3};
    line-height: 20px;
  }
  &:hover {
    // transform: translateY(-5px);
    background: ${(props) => props.theme.palette.button.grey.hover};
    background-position: 100% 50%;
  }
  &:active {
    transform: scale(0.96);
  }

  @media (max-width: ${WIDTH.mobileMax}) {
    min-width: 107px;
    min-height: 59px;
    width: 100%;
    height: 100%;
    font-size: 15px;
    line-height: 15px;
    padding: 8px;
    background-size: 100% 100%;

    &:hover {
      transform: none;
      background-position: none;
    }
    &:active {
      transform: scale(0.96);
    }
  }
`;
