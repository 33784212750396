import { useContext } from 'react';
import { useLocale } from '../../../../contexts/LocalizationContext';

import { HomePageV1Context } from '../../context/HomePageV1Context';
import { SectionWrapper } from '../../HomePageV1Style';
import PriceUnderSection from '../PriceUnderSection/PriceUnderSection';
import ProductCardsCluster from '../ProductCardsCluster/ProductCardsCluster';
import ProductsCardRowSkeleton from '../skeletons/ProductCardSkeletonRow/ProductsCardRowSkeleton';
import SmallCardSkeletonRow from '../skeletons/SmallCardSkeletonRow/SmallCardSkeletonRow';

function Section3() {
  const { bestSellingGamePoints, section3Ref } = useContext(HomePageV1Context);
  const { messages } = useLocale();
  const { best_selling_game_points_msg } = messages || {};

  // const trustpilotReviews = useTrustpilotReviews();

  // useEffect(() => {
  //   if (section3Ref.inView) {
  //     trustpilotReviews.fetchReviews();
  //   }
  // }, [section3Ref.inView]);

  return (
    <>
      <SectionWrapper surface="light">
        {!bestSellingGamePoints || !bestSellingGamePoints.length ? (
          <ProductsCardRowSkeleton />
        ) : (
          <ProductCardsCluster
            title={best_selling_game_points_msg ?? ''}
            products={bestSellingGamePoints}
            titleName="best_selling_game_points"
            collectionLink="/store/collection/best-selling-game-points"
            hideOOSProducts={true}
          />
        )}
      </SectionWrapper>
      <SectionWrapper surface="dark">
        {!bestSellingGamePoints || !bestSellingGamePoints.length ? (
          <SmallCardSkeletonRow />
        ) : (
          <PriceUnderSection />
        )}
      </SectionWrapper>
      {/* <SectionWrapper surface="light">
        {!bestSellingDLCs || !bestSellingDLCs.length ? (
          <ProductsCardRowSkeleton />
        ) : (
          <ProductCardsCluster
            title={best_selling_dlc_msg ?? ''}
            products={bestSellingDLCs}
            titleName="best_selling_dlcs"
            collectionLink="/store/collection/best-selling-dlcs"
            hideOOSProducts={true}
          />
        )}
      </SectionWrapper> */}
      {/* {trustpilotReviews.data === null || trustpilotReviews.data.length > 0 ? (
        <SectionWrapper surface="dark">
          {trustpilotReviews.loading ? (
            <SmallCardSkeletonRow />
          ) : (
            trustpilotReviews.data && (
              <TrustpilotRatingsSection reviews={trustpilotReviews.data} />
            )
          )}
        </SectionWrapper>
      ) : null} */}
      {/* <SectionWrapper surface="dark">
        {!bestSellingGamePoints || !bestSellingGamePoints.length ? (
          <SmallCardSkeletonRow />
        ) : (
          <CategorySection />
        )}
      </SectionWrapper> */}
    </>
  );
}

export default Section3;
