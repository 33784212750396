// create home page context

import { useRouter } from 'next/router';
import { createContext, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import useFetchRecommendations from '../../../hooks/recommendations/useFetchUserRecommendations';
import {
  BannerImageAPIInterface,
  HomepageCollectionsProductCardInterface,
  HomePageMainCollectionInterface,
} from '../../../Interface/HomepageCollectionsInterface';
import { RecombeeRecommendationI } from '../../../Interface/RecombeeRecommendationsInterface';
import { useAppSelector } from '../../../redux/hooks';
import { saveEventV3 } from '../../../utils/eventTracking';

export interface HomePageV1ContextInterface {
  // miniCollection: HomePageMiniCollectionInterface | null;
  bannerImages: BannerImageAPIInterface | null;
  mainCollection: HomePageMainCollectionInterface | null;
  section0Ref: { ref: any; inView: boolean; entry?: any };
  section1Ref: { ref: any; inView: boolean; entry?: any };
  section2Ref: { ref: any; inView: boolean; entry?: any };
  section3Ref: { ref: any; inView: boolean; entry?: any };
  section4Ref: { ref: any; inView: boolean; entry?: any };
  section5Ref: { ref: any; inView: boolean; entry?: any };
  bestSellersCollection: Array<HomepageCollectionsProductCardInterface> | null;
  bestSellingGiftcards: Array<HomepageCollectionsProductCardInterface> | null;
  bestSellingSubscriptions: Array<HomepageCollectionsProductCardInterface> | null;
  bestSellingGamePoints: Array<HomepageCollectionsProductCardInterface> | null;
  bestSellingDLCs: Array<HomepageCollectionsProductCardInterface> | null;
  recommendationsForYou: RecombeeRecommendationI | null | 'loading';
}

const initialState: HomePageV1ContextInterface = {
  // miniCollection: null,
  bannerImages: null,
  mainCollection: null,
  section0Ref: { ref: null, inView: false, entry: null },
  section1Ref: { ref: null, inView: false, entry: null },
  section2Ref: { ref: null, inView: false, entry: null },
  section3Ref: { ref: null, inView: false, entry: null },
  section4Ref: { ref: null, inView: false, entry: null },
  section5Ref: { ref: null, inView: false, entry: null },
  bestSellersCollection: null,
  bestSellingGiftcards: null,
  bestSellingSubscriptions: null,
  bestSellingGamePoints: null,
  bestSellingDLCs: null,
  recommendationsForYou: 'loading',
};

export const HomePageV1Context =
  createContext<HomePageV1ContextInterface>(initialState);

interface Props {
  // miniCollection: HomePageMiniCollectionInterface | null;
  mainCollection: HomePageMainCollectionInterface | null;
  bannerImages: BannerImageAPIInterface | null;
  children: any;
}

export const HomePageV1Provider = ({
  children,
  // miniCollection,
  mainCollection,
  bannerImages,
}: Props) => {
  const router = useRouter();
  const { deviceId, locationDetails } = useAppSelector((state) => state.global);
  // const {} = useHomePage({
  //   // miniCollection,
  //   mainCollection,
  //   countryCode,
  // });

  const section0Ref = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const section1Ref = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const section2Ref = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const section3Ref = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const section4Ref = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const section5Ref = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const recommendationsForYou = useFetchRecommendations({
    scenario: 'recommended_for_you',
    fromPage: 'homepage',
  });

  const [bestSellersCollection, setBestSellersCollection] =
    useState<Array<HomepageCollectionsProductCardInterface> | null>(null);

  const [bestSellingGiftcards, setBestSellingGiftcards] =
    useState<Array<HomepageCollectionsProductCardInterface> | null>(null);

  const [bestSellingSubscriptions, setBestSellingSubscriptions] =
    useState<Array<HomepageCollectionsProductCardInterface> | null>(null);

  const [bestSellingGamePoints, setBestSellingGamePoints] =
    useState<Array<HomepageCollectionsProductCardInterface> | null>(null);

  const [bestSellingDLCs, setBestSellingDLCs] =
    useState<Array<HomepageCollectionsProductCardInterface> | null>(null);

  useEffect(() => {
    if (
      section0Ref.inView &&
      deviceId &&
      locationDetails?.country_code &&
      recommendationsForYou.recombeeRecommendations === 'loading' &&
      typeof window !== 'undefined'
    ) {
      recommendationsForYou.getRecommendation();
      saveEventV3({
        action: 'load',
        category: 'homepage',
        from: router,
        label: 'section',
        properties: '0',
        value: ['recommended_for_you'],
      });
    }
  }, [
    deviceId,
    locationDetails.country_code,
    recommendationsForYou,
    router,
    section0Ref.inView,
  ]);

  useEffect(() => {
    if (section1Ref.inView) {
      if (mainCollection?.homepage_best_selling_games) {
        setBestSellersCollection(mainCollection?.homepage_best_selling_games);
      }
      saveEventV3({
        action: 'load',
        category: 'homepage',
        from: router,
        label: 'section',
        properties: '1',
        value: ['homepage_best_selling_games'],
      });
    }
  }, [section1Ref.inView, mainCollection]);

  useEffect(() => {
    if (section2Ref.inView) {
      if (mainCollection?.homepage_best_selling_giftcards) {
        setBestSellingGiftcards(
          mainCollection?.homepage_best_selling_giftcards
        );
      }
      saveEventV3({
        action: 'load',
        category: 'homepage',
        from: router,
        label: 'section',
        properties: '2',
        value: ['homepage_best_selling_giftcards'],
      });
    }
  }, [section2Ref.inView, mainCollection]);

  useEffect(() => {
    if (section3Ref.inView) {
      // if (mainCollection?.homepage_best_selling_dlc) {
      //   setBestSellingDLCs(mainCollection?.homepage_best_selling_dlc);
      // }
      if (mainCollection?.homepage_best_selling_gamepoints) {
        setBestSellingGamePoints(
          mainCollection?.homepage_best_selling_gamepoints
        );
      }
      saveEventV3({
        action: 'load',
        category: 'homepage',
        from: router,
        label: 'section',
        properties: '3',
        value: ['homepage_best_selling_gamepoints'],
      });
    }
  }, [section3Ref.inView, mainCollection]);

  useEffect(() => {
    if (section4Ref.inView) {
      if (mainCollection?.homepage_best_selling_subscriptions) {
        setBestSellingSubscriptions(
          mainCollection?.homepage_best_selling_subscriptions
        );
      }
      saveEventV3({
        action: 'load',
        category: 'homepage',
        from: router,
        label: 'section',
        properties: '4',
        value: ['homepage_best_selling_subscriptions'],
      });
    }
  }, [section4Ref.inView, mainCollection]);

  // useEffect(() => {
  //   if (section5Ref.inView) {
  //     if (mainCollection?.homepage_best_selling_gamepoints) {
  //       setBestSellingGamePoints(
  //         mainCollection?.homepage_best_selling_gamepoints
  //       );
  //     }
  //     saveEventV3({
  //       action: 'load',
  //       category: 'homepage',
  //       from: router,
  //       label: 'section',
  //       properties: '5',
  //       value: [],
  //     });
  //   }
  // }, [section5Ref.inView, mainCollection]);

  return (
    <HomePageV1Context.Provider
      value={{
        bannerImages,
        mainCollection,
        section0Ref,
        section1Ref,
        section2Ref,
        section3Ref,
        section4Ref,
        section5Ref,
        bestSellersCollection,
        bestSellingGiftcards,
        bestSellingSubscriptions,
        bestSellingGamePoints,
        bestSellingDLCs,
        recommendationsForYou: recommendationsForYou.recombeeRecommendations,
        // recommendationsForYou: recommendationsForYou.recombeeRecommendations,
        // miniCollection,
        // latestReleasedGames,
      }}
    >
      {children}
    </HomePageV1Context.Provider>
  );
};
