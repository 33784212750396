import { InView } from 'react-intersection-observer';
import CategoryTile from '../../../CategoryTile/CategoryTile';
import {
  CarousalItem,
  Carousel,
  CarouselContainer,
} from '../HomePageAnimations/HomePageAnimationsStyle';
import useHomePageSlidingAnimation from '../HomePageAnimations/useHomePageSlidingAnimation';

const AnimatedCategoryCards = ({ items, itemWidth, initialSpeed }: any) => {
  const { carouselRef, recursiveArray, setTemp, setTemp2 } =
    useHomePageSlidingAnimation({
      items,
      itemWidth,
      initialSpeed,
    });
  return (
    <InView as="div" onChange={(inView, entry) => setTemp2(inView)}>
      <CarouselContainer>
        <Carousel ref={carouselRef}>
          {recursiveArray?.map((_: any, index: any) =>
            index === recursiveArray.length - 1 ? (
              <InView
                as="div"
                onChange={(inView, entry) => setTemp(inView)}
                key={index}
              >
                <CarousalItem key={index}>
                  <CategoryTile category={_} />
                </CarousalItem>
              </InView>
            ) : (
              <>
                <CarousalItem key={index}>
                  <CategoryTile category={_} />
                </CarousalItem>
              </>
            )
          )}
        </Carousel>
      </CarouselContainer>
    </InView>
  );
};

export default AnimatedCategoryCards;
