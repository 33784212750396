import { useContext } from 'react';
import { useLocale } from '../../../../contexts/LocalizationContext';
import { HomePageV1Context } from '../../context/HomePageV1Context';
import { SectionWrapper } from '../../HomePageV1Style';
import ProductCardsCluster from '../ProductCardsCluster/ProductCardsCluster';
import ProductsCardRowSkeleton from '../skeletons/ProductCardSkeletonRow/ProductsCardRowSkeleton';

function Section4() {
  const { bestSellingSubscriptions, section4Ref } =
    useContext(HomePageV1Context);
  const { messages } = useLocale();
  const { best_selling_subscriptions_msg } = messages || {};
  // const trustpilotReviews = useTrustpilotReviews();

  // useEffect(() => {
  //   if (section4Ref.inView) {
  //     trustpilotReviews.fetchReviews();
  //   }
  // }, [section4Ref.inView]);

  return (
    <>
      <SectionWrapper surface="light">
        {!bestSellingSubscriptions || !bestSellingSubscriptions.length ? (
          <ProductsCardRowSkeleton />
        ) : (
          <ProductCardsCluster
            title={best_selling_subscriptions_msg ?? ''}
            products={bestSellingSubscriptions}
            titleName="best_selling_subscriptions"
            collectionLink="/store/collection/best-selling-subscriptions"
            hideOOSProducts={true}
          />
        )}
      </SectionWrapper>
      {/* {trustpilotReviews.data === null || trustpilotReviews.data.length > 0 ? (
        <SectionWrapper surface="dark">
          {trustpilotReviews.loading ? (
            <SmallCardSkeletonRow />
          ) : (
            trustpilotReviews.data && (
              <TrustpilotRatingsSection reviews={trustpilotReviews.data} />
            )
          )}
        </SectionWrapper>
      ) : null} */}
    </>
  );
}

export default Section4;
