import Link from 'next/link';
import { useRouter } from 'next/router';
import { memo, useCallback, useEffect, useState } from 'react';
import { useAnalytics } from '../../../../contexts/AnalyticsProvider';
import { useLocale } from '../../../../contexts/LocalizationContext';
import { getFilterList } from '../../../../helper/helper';
import { useAppSelector } from '../../../../redux/hooks';
import { saveEventV3 } from '../../../../utils/eventTracking';
import { formatBigNumber } from '../../../../utils/formatBigNumber';
import { WE_USER_EVENT_EXPLORE_BY_PRICE_CLICKED } from '../../../../utils/we';
import { PriceTile } from '../../../UnderPriceRangeSection/UnderPriceRangeSectionStyles';
import {
  PriceUnderSectionCardsContainer,
  ProductSectionWrapper,
  SectionTitle,
  SectionTitleContainer,
  SmallCardSectionContainer,
} from '../../HomePageV1Style';

function PriceUnderSection() {
  const router = useRouter();
  const { messages } = useLocale();
  const { under_msg, discover_by_price_msg } = messages || {};
  const global = useAppSelector((state) => state.global);
  const { cleverTap } = useAnalytics();

  const [rangeList, setRangeList] = useState<number[]>([]);

  useEffect(() => {
    if (global.locationDetails) {
      const list = getFilterList(global.locationDetails.multiplier);
      setRangeList(list);
    }
  }, [global.locationDetails]);

  const getTileLink = useCallback(
    (maxPrice: number, minPrice: number) => {
      const maxPriceFinal = (maxPrice / global.locationDetails.multiplier)
        .toFixed(2)
        .toString();
      const minPriceFinal = (minPrice / global.locationDetails.multiplier)
        .toFixed(2)
        .toString();

      return `/store?page=1&priceMax=${maxPriceFinal}&priceMin=${minPriceFinal}`;
    },
    [global.locationDetails]
  );

  const bgList = [
    'repeating-linear-gradient(135deg, #ABDCFF 0%, #0396FF 100%, #ABDCFF 0%)',
    'repeating-linear-gradient(135deg, #FAD7A1 0%, #E96D71 100%, #FAD7A1 0%)',
    'repeating-linear-gradient(135deg, #2AFADF 0%, #4C83FF 100%, #2AFADF 0%)',
    'repeating-linear-gradient(135deg, #CE9FFC 0%, #7367F0 100%, #CE9FFC 0%)',
    'repeating-linear-gradient(135deg, #FAB2FF 0%, #1904E5 100%, #FAB2FF 0%)',
    'repeating-linear-gradient(135deg, #F6D242 0%, #FF52E5 100%, #F6D242 0%)',
  ];

  return (
    <ProductSectionWrapper>
      <SectionTitleContainer>
        <SectionTitle>{discover_by_price_msg}</SectionTitle>
      </SectionTitleContainer>
      <SmallCardSectionContainer style={{ padding: '0 16px' }}>
        <PriceUnderSectionCardsContainer>
          {rangeList.map((cur, i) => (
            <Link
              key={i}
              passHref
              href={getTileLink(cur, rangeList[i - 1] || 0)}
            >
              <a>
                <PriceTile
                  bg={bgList[i]}
                  onClick={() => {
                    saveEventV3({
                      category: 'collection_card',
                      action: 'click',
                      label: 'explore_by_price',
                      properties: 'collection',
                      value: [
                        (global.locationDetails?.currency_symbol || 'EUR') +
                          formatBigNumber(cur),
                      ],
                      from: router,
                    });

                    WE_USER_EVENT_EXPLORE_BY_PRICE_CLICKED(
                      {
                        priceUnderWithCurrency:
                          (global.locationDetails?.currency_symbol || 'EUR') +
                          formatBigNumber(cur),
                        currency:
                          global.locationDetails?.currency_symbol || 'EUR',
                        pageUrl: window.location.href,
                      },
                      cleverTap
                    );
                    // handleTileClick(cur, rangeList[i - 1] || 0);
                  }}
                >
                  <span>{under_msg}</span>
                  {global.locationDetails?.currency_symbol || 'EUR'}
                  {formatBigNumber(cur)}
                </PriceTile>
              </a>
            </Link>
          ))}
        </PriceUnderSectionCardsContainer>
      </SmallCardSectionContainer>
    </ProductSectionWrapper>
  );
}

export default memo(PriceUnderSection);
