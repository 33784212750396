import { useContext } from 'react';
import { useLocale } from '../../../../contexts/LocalizationContext';
import { SectionWrapper } from '../../HomePageV1Style';
import { HomePageV1Context } from '../../context/HomePageV1Context';
import CategorySection from '../CategorySection/CategorySection';
import ProductCardsCluster from '../ProductCardsCluster/ProductCardsCluster';
import ProductsCardRowSkeleton from '../skeletons/ProductCardSkeletonRow/ProductsCardRowSkeleton';
import SmallCardSkeletonRow from '../skeletons/SmallCardSkeletonRow/SmallCardSkeletonRow';

function Section2() {
  const { bestSellingGiftcards } = useContext(HomePageV1Context);
  const { messages } = useLocale();
  const { best_selling_gift_cards_msg } = messages || {};

  return (
    <>
      <SectionWrapper surface="light">
        {!bestSellingGiftcards || !bestSellingGiftcards.length ? (
          <ProductsCardRowSkeleton />
        ) : (
          <ProductCardsCluster
            title={best_selling_gift_cards_msg ?? ''}
            products={bestSellingGiftcards}
            titleName="best_selling_gift_cards"
            collectionLink="/store/collection/best-selling-gift-cards"
            hideOOSProducts={true}
          />
        )}
      </SectionWrapper>
      <SectionWrapper surface="dark">
        {!bestSellingGiftcards || !bestSellingGiftcards.length ? (
          <SmallCardSkeletonRow />
        ) : (
          <CategorySection />
        )}
      </SectionWrapper>
      {/* <SectionWrapper surface="dark">
        {!bestSellingGiftcards || !bestSellingGiftcards.length ? (
          <SmallCardSkeletonRow />
        ) : (
          <PriceUnderSection />
        )}
      </SectionWrapper> */}
    </>
  );
}

export default Section2;
