import { memo, useContext } from 'react';

import MobileExploreSection from '../../../MobileExploreSection/MobileExploreSection';
import NewHeroCarousel from '../../../NewHeroCarousel/NewHeroCarousel';
import { HomePageV1Context } from '../../context/HomePageV1Context';
import { InvisibleBoxSpacing } from '../../HomePageV1Style';
import Section0 from '../sections/Section0';
import Section1 from '../sections/Section1';
import Section2 from '../sections/Section2';
import Section3 from '../sections/Section3';
import Section4 from '../sections/Section4';

function DesktopPage() {
  const {
    bannerImages,
    section0Ref,
    section1Ref,
    section2Ref,
    section3Ref,
    section4Ref,
    section5Ref,
  } = useContext(HomePageV1Context);

  return (
    <>
      <MobileExploreSection title="Explore" />

      <NewHeroCarousel bannerImages={bannerImages} />

      <InvisibleBoxSpacing />

      {/* <Section0 /> */}
      <div ref={section0Ref.ref}>
        <Section0 />
      </div>

      {/* // section 1 */}
      <div ref={section1Ref.ref}>
        <Section1 />
      </div>

      {/* // section 2 */}
      <div ref={section2Ref.ref}>
        <Section2 />
      </div>

      {/* // section 3 */}
      <div ref={section3Ref.ref}>
        <Section3 />
      </div>

      {/* // section 4 */}
      <div ref={section4Ref.ref}>
        <Section4 />
      </div>

      {/* // section 5 */}
      {/* <div ref={section5Ref.ref}>
        <Section5 />
      </div> */}
    </>
  );
}

export default memo(DesktopPage);
