import { memo } from 'react';
import { WIDTH } from '../../../../constants/screenResolution';
import { useLocale } from '../../../../contexts/LocalizationContext';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { getNumFromResolutionConstant } from '../../../../utils/getNumberFromResolutionConstant';
import { generateCategoriesList } from '../../../CategorySection/CategorySection';
import CategoryTile from '../../../CategoryTile/CategoryTile';
import {
  CategoryCardContainerAnimation,
  CatWrapper,
  ProductSectionWrapper,
  SectionTitle,
  SectionTitleContainer,
  SmallCardSectionContainer,
} from '../../HomePageV1Style';
import AnimatedCategoryCards from './AnimatedCategoryCards';

function CategorySection() {
  const { messages } = useLocale();

  const { discover_games_by_category_msg } = messages || {};

  const category_list = generateCategoriesList(messages);
  const { width } = useWindowDimensions();

  return (
    <ProductSectionWrapper animationTrue={true}>
      <SectionTitleContainer>
        <SectionTitle>{discover_games_by_category_msg}</SectionTitle>
      </SectionTitleContainer>
      <SmallCardSectionContainer>
        <CategoryCardContainerAnimation>
          {width && width > getNumFromResolutionConstant(WIDTH.tabletMax) ? (
            <AnimatedCategoryCards
              items={category_list}
              itemWidth={'120px'}
              initialSpeed={0.5}
            />
          ) : (
            category_list.map((category: any, index: number) => (
              <CatWrapper key={index}>
                <CategoryTile category={category} />
              </CatWrapper>
            ))
          )}
        </CategoryCardContainerAnimation>
      </SmallCardSectionContainer>
    </ProductSectionWrapper>
  );
}

export default memo(CategorySection);
